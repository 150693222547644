import { ref } from 'vue';
import { useStore } from 'vuex';

const drag = ref(false);

// const dropZones = ref([]);

export default function(){
    const store = useStore();

    const startDrag = (evt,item,parentCode,itemIndex) => {
        evt.dataTransfer.dropEffect = 'move';
        evt.dataTransfer.effectAllowed = 'move';
        evt.dataTransfer.setData('itemParent', parentCode);
        evt.dataTransfer.setData('itemIndex', itemIndex);
        evt.dataTransfer.setData('itemCode', item.code);
        evt.dataTransfer.setData("text/html", evt.target.outerHTML);

        store.commit('menu_item/startDrag',item.code);
        drag.value = true;
        // evt.dataTransfer.setData('text/plain', 'dummy');
    }

    const onDrop = (evt, parentCode, index) => {
        const itemCode = evt.dataTransfer.getData('itemCode');
        if(!itemCode){
            return;
        }
        const itemIndex = evt.dataTransfer.getData('itemIndex');
        const itemParent = evt.dataTransfer.getData('itemParent');
        let position = index;
        if((itemParent == parentCode || (itemParent == "null" && parentCode == null)) && position > itemIndex){
            position = position-1;
        }
        store.dispatch('menu_item/changePlace',{itemCode,parentCode,position});
        drag.value = false;
        // store.commit('menu_item/stopDrop');
    }

    const stopDrag = () => {
        store.commit('menu_item/stopDrag');
        drag.value = false;
    }

    return {
        startDrag,
        onDrop,
        stopDrag,
        drag
    }

}

