<template>
    <div class="menu-items-block" @drop="onDrop($event, 1)" :class="{drag_active: drag}" >
        <transition-group name="list" tag="div">
            <MenuItem v-for="(item,index) in menuItems" :key="item.code" :index="index" :menuItem="item" :parentCode="parentCode" />
        </transition-group>
        <MenuDropZone class="drop-position" :parentCode="parentCode" :index="menuItems.length" />
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import useMenuDrag from '@admin/composable/MenuDrag';

export default {
    name: "MenuItemsBlock",
    components: {
        MenuItem: defineAsyncComponent(() => import(/* webpackChunkName: "menu-item" */'@admin/components/cms/menu/MenuItem.vue')),
        MenuDropZone: defineAsyncComponent(() => import(/* webpackChunkName: "menu-drop-zone" */'@admin/components/cms/menu/MenuDropZone.vue')),
    },
    props: {
        menuItems: {
            type: Array,
            default: () => []
        },
        parentCode: {
            type: String,
            default: null
        } 
    },
    setup(){
        const { drag } = useMenuDrag();
        
        return {
            drag,
        }

    }
}
</script>

<style scoped>
.menu-items-block{
    background: #e9fff3;
    border: 1px solid #177542;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
}
.drag_active{
    padding: 0.5rem 0;

}
.menu-item.dragged .drag_active{
    padding: 0;
}
.list-move {
    transition: transform 0.8s ease;
}
</style>
